.footer{
  // position: absolute;
  width: 100%;
  bottom: 0;
}

.main-footer {
  flex: 1;
  min-height: 8.75rem;
  // background: #29a578;

  h5 {
    font-weight: bold;
  }

  a,
  p {
    margin: 0;
  }
  a {
    text-decoration: none;
    color: white;
    transition: all 0.3s;

    &:hover {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.781);
      font-weight: bold;
    }
  }
}

.social-links {
  opacity: .83;
}