.link {
  text-decoration: underline;
  transition: all 0.3s;
}

.alert-dialog {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
}