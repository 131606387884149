.floating-cart {
  right: 12px;
  bottom: 12px;
  z-index: 999;
}
.clean-btn {
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}