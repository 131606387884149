.splash {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  animation: pulsation 1s ease-in-out infinite alternate-reverse;

  @keyframes pulsation {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.15);
    }
  }
}
