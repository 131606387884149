.sidebar-panel-nav {
  width: 220px;
  padding: 16px;
  position: fixed;
  // border-right: 2px solid rgba($color: #000000, $alpha: 0.2);
  height: 100%;
  left: 0;

  ul {
    list-style: none;
  }
}

.link-panel {
  color: white;
}
