.old-price {
  color: rgb(0, 0, 0, 0.3);
  text-decoration: line-through;
  display: inline;
}

.delivery {
  display: inline;
}

.container_image{
  justify-content: center;
  min-height: 350px;
  max-height: 400px;
}

.image_product {
  width: 100%;
  height: 100%;
  object-fit: cover;
}