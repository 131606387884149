.product-card-image {
  height: 196px;
  object-fit: cover;
}

.highlight-image {
  filter: brightness(.75);
  object-fit: cover;
}

.center {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}


#nav-dropdown-categorias {
  .dropdown-menu {
    max-height: 70vh;
    overflow-y: auto;
  }
}