.store-logo {
  height: 100%;
  max-width: 100%;
  margin-right: 2%;
  object-fit: cover;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.description {
  white-space: pre-line
}
.container__store {
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (min-width: 576px){
  .container__store{
    display: flex;
    & img { width: 100%;
      height: 100%;
      width: 50%;
      height: 100%;
    }
  }

}

@media (min-width: 992px) {
  .container__store{
    display: flex;
    align-items: flex-start;
    justify-content:flex-start ;
    & img {
      width: 25%;
      height: 100%;
    }
  }
}